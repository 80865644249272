import React from "react"
import { Link } from "gatsby"
import Layout from '../../components/layout'
import trtData from '../../content/trt-data.json'

import Loadable from '@loadable/component';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Metatags from "../../components/Metatags";
const OwlCarousel = Loadable(() => import('react-owl-carousel'));


const CarjasoosPage = () => {
    function PortfolioBox(title, image, imgAlt, detailsURL, description, i) {
        return (
            <Link to={detailsURL}>
                <div className="portfolio-box" key={i}>
                    <div className="portfolio-img">
                        <img src={`../` + image} alt={imgAlt} />
                    </div>
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
            </Link>
        )
    }
    return (
        <Layout>
            <section className="trt-portfolio-details">
                <div className="container">
                    <div className="portfolio-banner">
                        <div className="row justify-content-between">
                            <div className="col-lg-6">
                                <Link to="/portfolio" className="back-arrow">
                                    <img
                                        src="../../images/back-arrow.png"
                                        alt=""
                                    />
                                    <label>Terug naar portfolio's</label>
                                </Link>
                                <h1 className="d-none d-sm-block">Paardenstallen</h1>
                            </div>
                            <div className="col-lg-5 text-right">
                                <img
                                    src="../../images/portfolio/banner-icon.png"
                                    alt=""
                                />
                                <h1 className="d-block d-sm-none text-left">Paardenstallen</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-7">
                            <div className="portfolio-details-slider">
                                <div className="portfolio-slider">
                                    <OwlCarousel
                                        className="owl-theme"
                                        loop
                                        autoplay
                                        animateIn="false"
                                        animateOut="fadeOut"
                                        lazyLoad="true"
                                        navSpeed="2000"
                                        smartSpeed="1500"
                                        mouseDrag="false"
                                        responsive={
                                            {
                                                0: {
                                                    items: 1,
                                                },
                                                400: {
                                                    items: 1,
                                                },
                                                740: {
                                                    items: 1,
                                                },
                                                940: {
                                                    items: 1,
                                                },
                                            }
                                        }
                                    >
                                        <div className="item">
                                            <img src="../../images/portfolio/Paardenstallen-1.jpg" alt="" />
                                        </div>
                                        <div className="item">
                                            <img src="../../images/portfolio/Paardenstallen-2.jpeg" alt="" />
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                            <div className="project-content">
                                
                                <p>Onze eigen roots liggen bij de productie van paardenstallen en aanverwante artikelen. Uiteraard doen wij werkbereiding voor dit bedrijf.</p>
                                <p>Klanttekeningen voor goedkeuring uitvoering</p>
                                <p>Productietekeningen bestaande uit</p>
                                <p>Lasersnijtekeningen koker en buis</p>
                                <p>Zaag, boor en buig / wals tekeningen</p>
                                <p>Lasersnijtekeningen plaat + kant en tap tekeningen</p>
                                <p>Lastekeningen</p>
                                <p>Verzinklijst overzichten met overzicht buitenmaten en kilo's</p>
                                <p>Eventueel poedercoatlijsten met m2 aanduidingen</p>
                                <p>Paklijsten en stuk lijsten</p>
                                <p>Volledige step-by-step montagehandleidingen</p>
                                <div className="content-title">
                                    <img
                                        src="../../images/technology-icon.png"
                                        alt=""
                                    />
                                    <h5>Technology We have Used</h5>
                                </div>
                                <div className="technology-details">
                                    <p>Invenor</p>
                                    <p>AutoCAD</p>
                                    <p>Excel</p>
                                </div>
                                <Link to="/portfolio" className="back-arrow mt-5">
                                    <img
                                        src="../../images/back-arrow.png"
                                        alt=""
                                    />
                                    <label>Terug naar portfolio's</label>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 mt-5">
                            {
                                trtData.portfolioBoxData.filter(({ title }) => title != 'Car Jasoos').slice(0, 3).map(({ title, image, imgAlt, detailsURL, description }, i) => PortfolioBox(title, image, imgAlt, detailsURL, description, i))
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default CarjasoosPage;